/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
  SafeAreaView,
  TouchableHighlight,
  Platform,
  Button,
  Image,
  Modal,
  Pressable,
} from 'react-native';

import { Card, TextInput } from 'react-native-paper';
import { useToast } from 'react-native-paper-toast';
import * as DocumentPicker from 'expo-document-picker';
// import * as FileSystem from 'expo-file-system';

import moment from 'moment';
import DropDown from 'react-native-paper-dropdown';
// import { numberWithCommas } from '../../components/common/common';
import { styles as DashboardStyles } from '../dashboard/DashboardStyles';

import Activity from '../../components/activityIndicator/ActivityIndicator';
import SideMenu from '../../components/SideMenu/SideMenu';
import Footer from '../../components/footer/footer';
import DropDownSelector from '../../components/DropDownSelector';
import { AccountService } from '../../components/common/account.service';
import ModalDropdown from 'react-native-modal-dropdown';
import TrackScreenHit from "../../utils/analytics"

import Row, { Col } from '../../components/Row';
import RequiredTextInput from '../../components/RequiredTextInput';

export default function LoanWeb({ navigation }: any) {
  const toaster = useToast();
  const productOptions = useSelector((state) => (state as any).productList);
  const accountService = new (AccountService as any)();
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [docName, setDocName] = useState('');
  const [loanComment, setLoanComment] = useState('');
  const [docDesc, setDocDesc] = useState('');
  const [amount, setAmount] = useState('');
  const [loanTerm, setLoanTerm] = useState('0');
  const [calculatorAmount, setCalculatorAmount] = useState(0);
  const [repaymentPeriod, setRepaymentPeriod] = useState(0);
  const [monthlyRepaymentAmount, setMonthlyRepaymentAmount] = useState(0);
  const [loanInterest, setLoanInterest] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);

  TrackScreenHit("Loans Page")


  const [templateForm, setTemplateForm] = useState({});
  const [templateData, setTemplateData] = useState({
    locale: 'en_GB',
    dateFormat: 'dd MMMM yyyy HH:mm z',
    loanType: 'individual',
  });
  const [loanProducts, setLoanProducts] = useState([]);
  const [calculatorLoanProducts, setCalculatorLoanProducts] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);
  const [product, setProduct] = useState('');

  const [genderList, setGenderList] = useState([
    {
      label: 'purpose',
      value: 'purpose',
    }
  ]);

  type Document = {
    name: string;
    uri: string;
  };

  // Required Error checking
  const [docNameError, setDocNameError] = useState('');
  const [docDescError, setDocDescError] = useState('');
  const [amountError, setAmountError] = useState('');

  useEffect(() => {
    const tempProducts: any = [];
    const calculatorTempProducts: any = [];
    if (productOptions) {
      productOptions.forEach((i: any) => {
        tempProducts.push({ label: i.name, value: i.id });
        calculatorTempProducts.push(i.name)
      });
    }

    console.log("tempProducts",tempProducts)
    setCalculatorLoanProducts(calculatorTempProducts)
    setLoanProducts(tempProducts);
  }, []);

  const onChangeLoanproducts = async (productId: any) => {
    setIsDone(false);
    const clientId = await accountService.getClientId();
    getLoanTemplate(clientId, productId, "main");
    setProduct(productId);
  };

  function getLoanTemplate(clientId: any, productId: any, section: any) {
    const individualTemplate = {
      templateType: 'individual',
      clientId,
      productId,
    };

    accountService
      .template(individualTemplate)
      .then((res: any) => {
        if(section === "calculator"){
          setCalculatorAmount(parseInt(res.data.principal));
          setLoanInterest(parseInt(res.data.interestRatePerPeriod))
        } else {
          
          const date = moment(new Date()).format('DD MMMM yyyy HH:mm');
          setTemplateForm(res.data);
          const tempobj = {};
          (tempobj as any).principal = res.data.principal;
          (tempobj as any).submittedOnDate = date + " " + Intl.DateTimeFormat().resolvedOptions().timeZone;
          (tempobj as any).expectedDisbursementDate = date + " " + Intl.DateTimeFormat().resolvedOptions().timeZone;
          setTemplateData({ ...templateData, ...tempobj });
          setAmount(res.data.principal);
          setLoanTerm(res.data.numberOfRepayments.toString());
          setCalculatorAmount(parseInt(res.data.principal));
          const updatedGenderList = [...genderList];
            if (res.data.loanPurposeOptions && res.data.loanPurposeOptions.length > 0) {
              res.data.loanPurposeOptions.forEach((purpose: any) => {
                updatedGenderList.push({
                  label: purpose.name,
                  value: purpose.name,
                });
              });
            }
          setGenderList(updatedGenderList);
          setIsDone(true);
        }
      })
      .catch((error: any) => {
        const text = 'Loan Application template Not Found ';
        console.log(text);
      });
  }

  const [doc, setDoc] = useState<Document | null>(null);
  const [imagePreview, setImagePreview] = useState('');

  const pickDocument = async () => {
    await DocumentPicker.getDocumentAsync({
      type: '*/*',
      copyToCacheDirectory: true,
    }).then((response) => {
      if (response.type === 'success') {
        const { file, name, mimeType } = response;
        setDoc({ name: response.name, uri: response.uri });
  
        let uri = response.uri;
        if (Platform.OS === 'android' && uri[0] === '/') {
          uri = `file://${uri}`;
          uri = uri.replace(/%/g, '%25');
        }
  
        setDoc(file);

        if (mimeType && mimeType.startsWith('image/')) {
          setImagePreview(uri);
        } else {
          setImagePreview('');
        }
      }
    });
  };

  const onSubmit = async () => {
    setLoader(true);

    if (!amount || isNaN(Number(amount)) || Number(amount) <= 0) {
      setAmountError('Please enter a valid amount');
      setLoader(false);
      return;
    }

    if (docName == "") {
      setDocNameError('Please enter a valid document name');
      setLoader(false);
      return;
    }

    if (product == "") {
      toaster.show({
        message: 'Please enter a valid Account Type.',
        duration: 5000,
        type: 'error',
        position: 'middle',
      });
      setLoader(false);
      return;
    }

    if (docDesc == "") {
      setDocDescError('Please enter a valid description');
      setLoader(false);
      return;
    }

    const clientId = await accountService.getClientId();

    const fileData = new FormData();
    fileData.append('file', doc);
    fileData.append('description', docDesc);
    fileData.append('name', docName);

    const loanTemp = {
      clientId,
      productId: product,
      loanTermFrequency: parseInt(loanTerm),
      loanTermFrequencyType: (templateForm as any).termPeriodFrequencyType.id,
      numberOfRepayments: parseInt(loanTerm),
      repaymentEvery: (templateForm as any).repaymentEvery,
      repaymentFrequencyType: (templateForm as any).repaymentFrequencyType.id,
      interestRatePerPeriod: (templateForm as any).interestRatePerPeriod,
      amortizationType: (templateForm as any).amortizationType.id,
      interestType: (templateForm as any).interestType.id,
      interestCalculationPeriodType: (templateForm as any).interestCalculationPeriodType.id,
      transactionProcessingStrategyId: (templateForm as any).transactionProcessingStrategyId,
    };
    (templateData as any).principal = amount;
    const data = { ...templateData, ...loanTemp };
    accountService
      .loan(data)
      .then((resp: any) => {
        const loanResponse = resp.data;

        toaster.show({
          message: 'Loan Application Submitted Successfully.',
          duration: 5000,
          type: 'success',
          position: 'middle',
        });

        if(loanComment) {
          let notes = {
            note : loanComment
          }
          accountService.notes(loanResponse.loanId, notes)
        }

        setLoader(false);
        if(doc) {
          setLoader(true);

          toaster.show({
            message: 'Uploading File In Progress',
            duration: 5000,
            type: 'warning',
            position: 'middle',
          });

          submitFile(loanResponse.loanId, fileData);
        }
      })
      .catch((error: any) => {
        setLoader(false);
        const text = 'Loan Application Not Submitted Kindly contact the Maintainer ';
        console.log(error, text);

        toaster.show({
          message: 'Loan Application Not Successful.',
          duration: 5000,
          type: 'error',
          position: 'middle',
        });

      });
  };

  const submitFile = async (loanId: any, data: any) => {
    let text = '';
    accountService
      .loanDocUpload(loanId, data)
      .then((response: any) => {
        toaster.show({
          message: 'File Uploaded Submitted Successfully',
          duration: 5000,
          type: 'success',
          position: 'middle',
        });
        setLoader(false);
        (navigation as any).replace('Home', { screen: 'DashBoard' });
      })
      .catch((error: any) => {
        setLoader(false);
        text = 'File upload Not successful ';
        console.log(error);
      });
  };

  const handleDropdownSelect = async (index: any, option: any) => {
    setIsDone(false);
    const selProduct:any = []
    productOptions.filter(function(item:any){ item.name == option ? selProduct.push(item) : null})
    const productId = selProduct[0].id
   
    const clientId = await accountService.getClientId();
    getLoanTemplate(clientId, productId, "calculator");
  };

  return (
    <View style={[DashboardStyles.web]}>
      <Modal
        animationType="fade"
        // eslint-disable-next-line react/jsx-boolean-value
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
          <Row>
            <Col customStyles={{ flex: 2 }}>
              <Text style={styles.cardHeader}>Loan Calculator</Text>
            </Col>
          </Row>
            
            <View style={styles.mainDropdownContainer}>
              <ModalDropdown
                options={calculatorLoanProducts}
                defaultValue="Select Loan Product"
                style={styles.dropdownContainer}
                textStyle={styles.dropdownText}
                dropdownTextStyle={styles.dropdownListItemText}
                onSelect={handleDropdownSelect}
              />
            </View>
            <Row>
              <Col customStyles={{ flex: 1 }}>
                <Text style={[styles.label,{fontSize:14, textAlign: "left"}]}>Loan Interest</Text>
                <TextInput
                  placeholder="Loan Interest"
                  value={loanInterest.toString()}
                  mode="outlined"
                  outlineColor="#2679b0"
                  activeOutlineColor="#2679b0"
                />
               
              </Col>
            </Row>
            <Row>
              <Col customStyles={{ flex: 1 }}>
                <Text style={[styles.label,{fontSize:14}]}>Loan Principal</Text>
                <TextInput
                  placeholder="Loan Principal"
                  value={calculatorAmount.toString()}
                  onChangeText={(e: any) => setCalculatorAmount(e)}
                  mode="outlined"
                  outlineColor="#2679b0"
                  activeOutlineColor="#2679b0"
                />
              </Col>
            </Row>
            <Row>
              <Col customStyles={{ flex: 1 }}>
                <Text style={[styles.label,{fontSize:14}]}>Repayment Period in months</Text>
                <TextInput
                  placeholder="Repayment Period"
                  onChangeText={(e: any) => setRepaymentPeriod(e)}
                  value={repaymentPeriod.toString()}
                  mode="outlined"
                  outlineColor="#2679b0"
                  activeOutlineColor="#2679b0"
                />
              </Col>
            </Row>

            <Row>
              <Text style={[styles.label,{fontSize:14}]}>Monthly Repayment Amount: 
                {"\n"}
                <Text style={{color:"green"}}>{monthlyRepaymentAmount}</Text>
              </Text>
            </Row>

            <Row>
              <View style={{ flexDirection: 'column', flex: 2 }}>
                <Pressable 
                  style={[styles.button, styles.buttonClose]} 
                  onPress={
                    function(){
                      setMonthlyRepaymentAmount((((loanInterest / 100) * calculatorAmount) + calculatorAmount) / repaymentPeriod)
                    }}
                >
                  <Text style={styles.textStyle}>Calculate monthly repayment</Text>
                </Pressable>

                <Pressable
                  style={[styles.button, styles.cancel]}
                  onPress={() => setModalVisible(!modalVisible)}
                >
                  <Text style={styles.textStyle}>Close</Text>
                </Pressable>
              </View>
            </Row>
          </View>
        </View>
      </Modal>
      <Row>
        <Col customStyles={{ flex: 1, alignItems: 'center' }}>
          <SideMenu navigation={navigation} page="Loan" />
        </Col>

        <Col customStyles={{ flex: 4 }}>
          {/* <View> */}

          <Row>
            <Col
              customStyles={{
                flex: 1,
              }}
            >
              <Card style={[DashboardStyles.card, { paddingLeft: 20 }]}>
                <ImageBackground
                  source={require('../../assets/kb-blue-background.png')}
                  style={[
                    {
                      flex: 1,
                      width: 200,
                      marginTop: 10,
                      paddingVertical: 100,
                      height: '90%',
                      position: 'absolute',
                      right: 0,
                    },
                  ]}
                />

                <Card.Content>
                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <Text style={styles.cardHeader}>Apply For Loan</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ width: '100%' }}>
                      <Row>
                        <Col customStyles={{ flex: 1 }}>
                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.label}>
                                Select Loan
                                <Text style={{color: 'red'}}>*</Text>
                              </Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>

                              <Row>
                                <Col customStyles={{ flex: 1 }}>
                                  <SafeAreaView>
                                    <DropDown
                                      placeholder="Select Loan Product"
                                      mode="outlined"
                                      visible={showDropDown}
                                      showDropDown={() => setShowDropDown(true)}
                                      onDismiss={() => setShowDropDown(false)}
                                      value={product}
                                      setValue={(value) => onChangeLoanproducts(value)}
                                      list={loanProducts}
                                    />
                                  </SafeAreaView>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <RequiredTextInput
                                label="Principal Amount"
                                value={isDone ? amount : '0'}
                                onChange={(e: any) => setAmount(e)}
                                required
                                error={amountError}
                                mode="outlined"
                                outlineColor="#2679b0"
                                activeOutlineColor="#2679b0"
                                style={[DashboardStyles.dateLabel]}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <RequiredTextInput
                                label="Document Name"
                                value={docName}
                                onChange={setDocName}
                                required
                                error={docNameError}
                                mode="outlined"
                                outlineColor="#2679b0"
                                activeOutlineColor="#2679b0"
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.label}>Upload Document </Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1, marginBottom: 5 }}>
                              <View style={styles.container}>
                                <Button title="Select Document" onPress={pickDocument} />

                                {imagePreview ? (
                                  <View style={{ padding: 10 }}>
                                    <Image
                                      source={{ uri: imagePreview }}
                                      style={{ width: 100, height: 100 }}
                                    />
                                  </View>
                                ) : doc ? (
                                  <View style={{ padding: 10 }}>
                                    <Text style={styles.label}>Attached Document:</Text>
                                    <Text style={{fontSize: 17}}>{doc.name.toUpperCase()}</Text>
                                  </View>
                                ) : null}
                              </View>
                            </Col>
                          </Row>
                          
                        </Col>

                        <Col
                          customStyles={{
                            flex: 1,
                            marginLeft: 20,
                          }}
                        >
                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.label}>
                                Purpose
                                <Text style={{color: 'red'}}>*</Text>
                              </Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <DropDownSelector
                                placeholder="Purpose of the Loan "
                                list={genderList}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <RequiredTextInput
                                label="Loan Term"
                                value={loanTerm}
                                onChange={(e: any) => setLoanTerm(e)}
                                required
                                mode="outlined"
                                outlineColor="#2679b0"
                                activeOutlineColor="#2679b0"
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1}}>
                              <RequiredTextInput
                                label="Document Description"
                                value={docDesc}
                                onChange={(e: any) => setDocDesc(e)}
                                required
                                error={docDescError}
                                mode="outlined"
                                outlineColor="#2679b0"
                                activeOutlineColor="#2679b0"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.label}>.</Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Button title="Loan Calculator" onPress={() => setModalVisible(true)} />
                            </Col>
                          </Row>

                        </Col>
                      </Row>

                      <Row>
                        <Col customStyles={{ flex: 1 }}>
                          <RequiredTextInput
                            label="Loan Description / Comment"
                            value={loanComment}
                            onChange={setLoanComment}
                            required
                            // error={docNameError}
                            mode="outlined"
                            outlineColor="#2679b0"
                            activeOutlineColor="#2679b0"
                          />
                        </Col>
                      </Row>

                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ width: '75%' }}>
                      <View style={styles.divider} />
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      {loader && <Activity />}

                      <TouchableHighlight onPress={() => onSubmit()} disabled={loader}>
                        <View style={[styles.depositButton, loader && styles.disabledButton]}>
                          <Text style={{ color: 'white', fontWeight: 'bold' }}>{loader ? 'Processing...' : 'Send'}</Text>
                        </View>
                      </TouchableHighlight>
                    </Col>
                  </Row>
                </Card.Content>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Footer />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cardHeader: {
    fontSize: 30,
    color: '#2679b0',
    fontWeight: 'bold',
    marginTop: 20,
  },
  disabledButton: {
    backgroundColor: '#a9a9a9',
  },
  divider: {
    borderBottomWidth: 2,
    borderBottomColor: '#2679b0',
    marginTop: 10,
    // marginLeft: 5,
    marginBottom: 20,
    // width: '96%',
  },
  depositButton: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'rgb(30,136,229)',
    padding: 10,
    borderRadius: 15,
    marginTop: 20,
    width: 200,
  },
  label: {
    fontSize: 18,
    color: '#2679b0',
    fontWeight: 'bold',
  },
  subtiteLabel: {
    fontSize: 14,
    color: 'grey',
    fontWeight: 'bold',
  },
  mobilemoneywebImages: {
    width: '90%',
    height: 80,
    // borderRadius: 20,
    borderBottomRightRadius: 20,
  },
  loan: {
    padding: 5,
    color: 'black',
    fontSize: 16,
    fontWeight: 'normal',
    letterSpacing: 1,
  },
  centeredView: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  modalView: {
    margin: 10,
    width: 400,

    backgroundColor: 'white',
    borderRadius: 10,
    padding: 2,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1.0,
    shadowRadius: 7,
    elevation: 10,
  },
  header: {
    fontSize: 18,
    padding: 10
  },
  inputs: {
    borderWidth: 1,
    width: 250,
    height: 50,
    padding: 10,
    margin: 10,
    color: '#000',
    fontSize: 16,
  },
  button: {
    borderRadius: 5,
    padding: 10,
    elevation: 2,
    margin: 8,
    width: 200,
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cancel: {
    backgroundColor: 'gray',
  },

  dropdownText: {
    fontSize: 16,
    color: '#333',
  },
  
  dropdownListItemText: {
    fontSize: 16,
    color: '#333',
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  mainDropdownContainer: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  dropdownContainer: {
    width: '100%',
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderColor: '#ccc',
    borderRadius: 0,
    marginTop: 10,
  },
});

