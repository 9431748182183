/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  Image,
  ImageBackground,
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import { FontAwesome, Ionicons, MaterialIcons } from '@expo/vector-icons';
import { Provider,useSelector } from 'react-redux';
import { Card, Title, Button } from 'react-native-paper';
import Row, { Col } from '../../components/Row';
import Footer from '../../components/footer/footer';
import { styles } from './DashboardStyles';
import SideMenu from '../../components/SideMenu/SideMenu';
import { numberWithCommas } from '../../components/common/common';
import { store } from '../../redux/index';
import TrackScreenHit from "../../utils/analytics"
import { Constants } from '../../Constants/Constants';

const { LOADING_TEXT } = Constants;

function PageBody({ navigation, route }: any) {
  const announcements = [];
  const totalSavings = useSelector((state) => (state as any).totalSavings);
  
  const totalLoan = useSelector((state) => (state as any).totalLoan);
  const transactions = useSelector((state) => (state as any).transactions);
  TrackScreenHit("Dashboard Page")

  const [isStatisticsEnabled, setIsStatisticEnabled] = useState();
  const [statistics, setStatistics] = useState({
    totalLoans: '0',
    totalSavings: '0',
    totalShares: '0',
  });
  


  const statisticsView = () => {
    if (isStatisticsEnabled == true) {
      return (
        <View style={styles.saccoCard}>
          <Card style={[styles.card, styles.cardStyles]}>
            <Card.Content>
              <Row>
                <Col customStyles={{ flex: 1 }}>
                  <View style={{ width: '90%' }}>
                    <Row>
                      <Col customStyles={{ flex: 1.4 }}>
                        <View>
                          <Title style={[styles.saccoText]}>Total SACCO Contributions</Title>
                        </View>
                      </Col>
                      <Col>
                        <View style={[styles.verticalDivider]} />
                      </Col>
                    </Row>
                  </View>
                </Col>
                <Col customStyles={{ flex: 1 }}>
                  <View style={{ width: '90%' }}>
                    <Row>
                      <Col customStyles={{ flex: 1 }}>
                        <View style={{ marginLeft: '6%' }}>
                          <Title style={[styles.saccoText]}>Total Loans:</Title>
                          <Title style={[styles.saccoText]}>Total Savings:</Title>
                          <Title style={[styles.saccoText]}>Total Shares:</Title>
                        </View>
                      </Col>
                      <Col customStyles={{ flex: 1 }}>
                        <View style={{ marginLeft: '6%' }}>
                          <Title style={[styles.saccoFigures, { color: '#5eb158' }]}>
                            {numberWithCommas(statistics.totalLoans)}
                          </Title>
                          <Title style={[styles.saccoFigures, { color: '#5eb158' }]}>
                            {numberWithCommas(statistics.totalSavings)}
                          </Title>
                          <Title style={[styles.saccoFigures, { color: '#5eb158' }]}>
                            {numberWithCommas(statistics.totalShares)}
                          </Title>
                        </View>
                      </Col>
                    </Row>
                  </View>
                </Col>
              </Row>
            </Card.Content>
          </Card>
        </View>
      );
    } else {
      return null;
    }
  };

  const announcementsView = () => {
    if (announcements.length > 0) {
      return (
        <ScrollView>
          <View>
            <View style={[styles.recentTransactionDivider]} />

            <View style={{ paddingTop: '15px', paddingBottom: '15px' }}>
              <Row>
                <Col
                  customStyles={{
                    flex: 2,
                    alignItems: 'center',
                  }}
                >
                  <Image
                    style={{
                      height: '55px',
                      width: '55px',
                      borderWidth: 1,
                      borderRadius: 150 / 2,
                      borderColor: '#000',
                    }}
                    source={require('../../assets/appIcon.png')}
                  />
                </Col>
                <Col customStyles={{ flex: 8 }}>
                  <Text style={styles.versionlabel}>New Update v3.0</Text>
                  <Text style={styles.versionContext}>
                    New Release coming soon {' '}
                  </Text>
                  <Text style={styles.versionSubtitle}>kanzu banking</Text>
                </Col>
                <Col
                  customStyles={{
                    flex: 2,
                    alignItems: 'left',
                    paddingLeft: '5px',
                  }}
                >
                  <Text style={styles.versionlabel}>Date</Text>
                  <Text style={styles.versionContext}>2/03/2022</Text>
                </Col>
              </Row>
              <View style={[styles.recentTransactionDivider]} />
            </View>

            <View>
              <Row>
                <Col
                  customStyles={{
                    flex: 2,
                    alignItems: 'center',
                  }}
                >
                  <Image
                    style={{
                      height: '55px',
                      width: '55px',
                      borderWidth: 1,
                      borderRadius: 150 / 2,
                      borderColor: '#000',
                    }}
                    source={require('../../assets/appIcon.png')}
                  />
                </Col>
                <Col customStyles={{ flex: 8 }}>
                  <Text style={styles.versionlabel}>New Update v3.0</Text>
                  <Text style={styles.versionContext}>
                    The dash willnot go the dash willnot go the dash willnot go{' '}
                  </Text>
                  <Text style={styles.versionSubtitle}>kanzu banking</Text>
                </Col>
                <Col
                  customStyles={{
                    flex: 2,
                    alignItems: 'left',
                    paddingLeft: '5px',
                  }}
                >
                  <Text style={styles.versionlabel}>Date</Text>
                  <Text style={styles.versionContext}>2/03/2022</Text>
                </Col>
              </Row>
              <View style={[styles.recentTransactionDivider]} />
            </View>

            <View>
              <Row>
                <Col
                  customStyles={{
                    flex: 2,
                    alignItems: 'center',
                  }}
                >
                  <Image
                    style={{
                      height: '55px',
                      width: '55px',
                      borderWidth: 1,
                      borderRadius: 150 / 2,
                      borderColor: '#000',
                    }}
                    source={require('../../assets/appIcon.png')}
                  />
                </Col>
                <Col customStyles={{ flex: 8 }}>
                  <Text style={styles.versionlabel}>New Update v3.0</Text>
                  <Text style={styles.versionContext}>
                    The dash willnot go the dash willnot go the dash willnot go{' '}
                  </Text>
                  <Text style={styles.versionSubtitle}>kanzu banking</Text>
                </Col>
                <Col
                  customStyles={{
                    flex: 2,
                    alignItems: 'left',
                    paddingLeft: '5px',
                  }}
                >
                  <Text style={styles.versionlabel}>Date</Text>
                  <Text style={styles.versionContext}>2/03/2022</Text>
                </Col>
              </Row>
              <View style={[styles.recentTransactionDivider]} />
            </View>
          </View>
        </ScrollView>
      );
    }
    return (
      <View>
        <Row>
          <Col customStyles={{ flex: 1 }}>
            <Text style={styles.datelabel}>No Announcement available </Text>
          </Col>
        </Row>
      </View>
    );
  };

  const recentTxt = () => {
    return (
      <>
        {transactions.slice(0, 6).map((value:any, index:any) =>
          (value as any)?.transactionType === 'Deposit' ? (
            <React.Fragment key={index}>
              <View key={'deposit-' + index} style={{ marginTop: '12px', marginBottom: '12px' }}>
                <Row>
                  <Col customStyles={{ flex: 6 }}>
                    <Text style={[styles.versionlabel, { color: '#5eb158' }]}>
                      + {numberWithCommas((value as any)?.amount)}
                    </Text>
                    <Text style={styles.versionContext}>{(value as any)?.transactionType}</Text>
                  </Col>
                  <Col
                    customStyles={{
                      flex: 2,
                      alignItems: 'left',
                      paddingLeft: '5px',
                    }}
                  >
                    <Text style={[styles.versionContext]}>{(value as any)?.transactionDate}</Text>
                    <Text style={styles.versionContext}>{(value as any)?.accountNumber}</Text>
                  </Col>
                </Row>
              </View>
              <View key={'divider-deposit-' + index} style={[styles.recentTransactionDivider]} />
            </React.Fragment>
          ) : (
            <React.Fragment key={index}>
              <View key={'withdrawal-' + index} style={{ marginTop: '12px', marginBottom: '12px' }}>
                <Row>
                  <Col customStyles={{ flex: 6 }}>
                    <Text style={[styles.versionlabel]}>
                      - {numberWithCommas((value as any)?.amount)}
                    </Text>
                    <Text style={[styles.versionContext]}>{(value as any)?.transactionType}</Text>
                  </Col>
                  <Col
                    customStyles={{
                      flex: 2,
                      alignItems: 'left',
                      paddingLeft: '5px',
                    }}
                  >
                    <Text style={[styles.versionContext]}>{(value as any)?.transactionDate}</Text>
                    <Text style={styles.versionContext}>{(value as any)?.accountNumber}</Text>
                  </Col>
                </Row>
              </View>
              <View key={'divider-withdrawal-' + index} style={[styles.recentTransactionDivider]} />
            </React.Fragment>
          )
        )}
      </>
    );
  };

  return (
    <View style={styles.web}>
      <Row>
        <ImageBackground
          source={require('../../assets/kb-blue-background.png')}
          style={[
            {
              flex: 1,
              width: 70,
              marginTop: 30,
              paddingVertical: 100,
              height: '90%',
              position: 'absolute',
              right: 0,
            },
          ]}
        />

        <Col customStyles={{ flex: 1.2, alignItems: 'center' }}>
          <Provider store={store}>
            <SideMenu navigation={navigation} page="Dashboard" style={{ height: '30px' }}/>
          </Provider>
        </Col>

        <Col customStyles={{ flex: 4 }}>
          <View style={{}}>
            <Row>
              <View style={{ flexDirection: 'column', flex: 2.3 }}>
                <View style={{ flexDirection: 'row' }}>
                  <Col customStyles={{ flex: 1 }}>
                    <Card
                      style={[styles.card]}
                      onPress={() => {
                        navigation.navigate('Accounts', { tab: 'savings' });
                      }}
                    >
                      <Card.Content>
                        <Row>
                          <Col customStyles={{ flex: 3 }}>
                            <Title style={styles.savingsText}>Total Savings</Title>
                          </Col>

                          <Col customStyles={{ flex: 1 }}>
                            <Image
                              style={{ width: 60, height: 60, position: 'absolute' }}
                              source={require('../../assets/total_savings.png')}
                            />
                          </Col>
                        </Row>

                        <Text style={[styles.currencyText, { color: '#5eb158' }]}>
                          {totalSavings === LOADING_TEXT ? totalSavings : numberWithCommas(totalSavings)}
                        </Text>

                        <Text style={styles.cardSubtitleText}>. </Text>

                        <Col customStyles={{ flex: 0.6 }}>
                          <View style={{ paddingLeft: '5%' }}>
                            <Button
                              style={{ shadowColor: '#009900', shadowRadius: 10, shadowOpacity: 1 }}
                              mode="contained"
                              color="#009900"
                              onPress={() =>
                                navigation.navigate('Payment', {
                                  // accountId: (data as any)?.id,
                                  accountType: 'Savings',
                                })
                              }
                            >
                              Make Savings Deposit
                            </Button>
                          </View>
                        </Col>
                      </Card.Content>
                    </Card>
                  </Col>

                  <Col customStyles={{ flex: 1 }}>
                    <Card
                      style={[styles.card]}
                      onPress={() => {
                        navigation.navigate('Accounts', { tab: 'loans' });
                      }}
                    >
                      <Card.Content>
                        <Row>
                          <Col customStyles={{ flex: 3 }}>
                            <Title style={[styles.savingsText]}>Total Loans</Title>
                          </Col>

                          <Col customStyles={{ flex: 1 }}>
                            <Image
                              style={{ width: 70, height: 30, position: 'absolute' }}
                              source={require('../../assets/apply_loan.png')}
                            />
                          </Col>
                        </Row>

                        <Text style={[styles.currencyText, { color: '#dc3832' }]}>
                          {totalLoan === LOADING_TEXT ? totalLoan : numberWithCommas(totalLoan)}
                        </Text>

                        <Text style={styles.cardSubtitleText}>.</Text>

                        <Col customStyles={{ flex: 0.6 }}>
                          <View style={{ paddingLeft: '5%' }}>
                            <Button
                              style={{ shadowColor: '#dc3832', shadowRadius: 10, shadowOpacity: 1 }}
                              mode="contained"
                              color="#dc3832"
                              onPress={() =>
                                navigation.navigate('Payment', {
                                  // accountId: (data as any)?.id,
                                  accountType: 'Loans',
                                })
                              }
                            >
                              Make Loan Repayment
                            </Button>
                          </View>
                        </Col>
                      </Card.Content>
                    </Card>
                  </Col>
                </View>
                <div>{statisticsView()}</div>

                <View
                  style={{
                    flexDirection: 'row',
                    marginTop: '15px',
                    width: '100%',
                    height: isStatisticsEnabled ? 360 : 510,
                  }}
                >
                  <Card style={[styles.card, styles.cardStyles]}>
                    <Card.Content>
                      <Row>
                        <Col customStyles={{ flex: 1 }}>
                          <View
                            style={{
                              flexDirection: 'row',
                              paddingTop: '20px',
                              paddingBottom: '20px',
                            }}
                          >
                            <View>
                              <Text style={styles.cardHeaders}>Announcements & Notifications</Text>
                            </View>
                            <View style={{ width: '100%' }}>
                              <Ionicons
                                name="notifications-sharp"
                                size={30}
                                color="grey"
                                style={{ marginLeft: '40%' }}
                              />
                            </View>
                          </View>
                        </Col>
                      </Row>

                      {announcementsView()}
                    </Card.Content>
                  </Card>
                </View>
              </View>

              <Col
                customStyles={{
                  flex: 1.6,
                }}
              >
                <Card
                  style={[
                    styles.card,
                    { height: '675px', paddingLeft: '15px', paddingRight: '15px' },
                  ]}
                >
                  <Card.Content>
                    <Row>
                      <Col customStyles={{ flex: 1 }}>
                        <View
                          style={{
                            flexDirection: 'row',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                          }}
                        >
                          <View>
                            <Text style={styles.cardHeaders}>Recent Transactions</Text>
                          </View>
                          <View style={{ width: '100%' }}>
                            <MaterialIcons
                              name="schedule"
                              size={30}
                              color="grey"
                              style={[{ marginLeft: '40%' }]}
                            />
                          </View>
                        </View>
                      </Col>
                    </Row>

                    <View style={{ height: 500 }}>
                      <ScrollView>
                        <View>
                          <View style={[styles.recentTransactionDivider]} />
                          {recentTxt()}
                        </View>
                      </ScrollView>
                    </View>

                    <Row>
                    </Row>
                  </Card.Content>
                </Card>
              </Col>
            </Row>
          </View>
        </Col>
      </Row>

      <Footer />
    </View>
  );
}

export default function DashboardWeb({ navigation, route }: any) {
  return (
    <Provider store={store}>
      <PageBody navigation={navigation} route={route} />
    </Provider>
  );
}
