export const Constants = {
  BASE_URL: 'https://api.kanzubanking.com/fineract-provider/api/v1/',
  MOMO_BASE_URL: 'https://momoapi.kanzubanking.com',
  USSD_BASE_URL: 'https://ussd.kanzubanking.com',
  LOADING_TEXT: 'Loading...',

  CHARGES:[{"max":499,"min":0,"amount":1200},
    {"max":2500,"min":500,"amount":1200},
    {"max":5000,"min":2501,"amount":1200},
    {"max":15000,"min":5001,"amount":1800},
    {"max":30000,"min":15001,"amount":1950},
    {"max":45000,"min":30001,"amount":3500},
    {"max":60000,"min":45001,"amount":3700},
    {"max":125000,"min":60001,"amount":3900},
    {"max":250000,"min":125001,"amount":4100},
    {"max":500000,"min":250001,"amount":4300},
    {"max":1000000,"min":500001,"amount":2500},
    {"max":5000000,"min":1000001,"amount":500}
  ] 

};

export default { Constants };
