// @ts-nocheck
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
  SafeAreaView,
  TouchableHighlight,
  Platform,
  Image,
} from 'react-native';
import DropDown from 'react-native-paper-dropdown';
import { Card, TextInput, Button } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';
import moment from 'moment';
import * as DocumentPicker from 'expo-document-picker';
import { useToast } from 'react-native-paper-toast';
import { styles as DashboardStyles } from '../dashboard/DashboardStyles';
import Activity from '../../components/activityIndicator/ActivityIndicator';
import SideMenu from '../../components/SideMenu/SideMenu';
import Footer from '../../components/footer/footer';
import { AccountService } from '../../components/common/account.service';
import TrackScreenHit from '../../utils/analytics';

import { useSelector, Provider } from 'react-redux';
import { store } from '../../redux/index';


import Row, { Col } from '../../components/Row';
import RequiredTextInput from '../../components/RequiredTextInput';

export default function SelfDepositWeb({ navigation }:any) {
  const toaster = useToast();
  const accountService = new (AccountService as any)();
  const [docName, setDocName] = useState('');
  const [docDesc, setDocDesc] = useState('');
  const [amount, setAmount] = useState('');

  const [loader, setLoader] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [choices, setChoices] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showDropDown1, setShowDropDown1] = useState(false);
  const [accounttype, setAccountType] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [date, setDate] = useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [addedAccounts, setAddedAccounts] = React.useState(true);

  const [filteredSavingsAccounts, setFilteredSavingsAccounts] = useState([]);
  const [filteredLoanAccounts, setFilteredLoanAccounts] = useState([]);
  let loanAccounts = useSelector((state) => (state as any).loanAccounts);
  let savingsAccounts = useSelector((state) => (state as any).savingsAccounts);

  TrackScreenHit("Self Reported Transaction Page")

  // Required Error checking
  const [docNameError, setDocNameError] = useState('');
  const [docDescError, setDocDescError] = useState('');
  const [amountError, setAmountError] = useState('');


  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params: any) => {
      setOpen(false);
      setDate(params.date);
    },
    [setOpen, setDate]
  );

  const accountsMap = {
    'Savings': filteredSavingsAccounts,
    'Loan': filteredLoanAccounts
  };

  const showAccounts = () => {
    const tempObj = {
      id: choices.length + 1,
      locale: 'en',
      accountType: accounttype,
      accountId: parseInt(accountNumber, 10),
      amount: amount,
    };
    setChoices([...choices, tempObj]);
    setAddedAccounts(false)
    setShow(true);
  }

  useEffect(() => {

    const savings = savingsAccounts.filter(acc => acc.status.active === true).map((x: any) => ({
      label: x.accountNo,
      value: x.accountNo,
    }));
    const loans = loanAccounts.filter(acc => acc.status.active === true).map((x: any) => ({
      label: x.accountNo,
      value: x.accountNo,
    }));

    setFilteredSavingsAccounts(savings)
    setFilteredLoanAccounts(loans)

  }, []);

  const onSubmit = async () => {
    // Set loader to true at the start
    setLoader(true);
  
    // Validation checks
    if (!amount || isNaN(Number(amount)) || Number(amount) <= 0) {
      setAmountError('Please enter a valid amount');
      setLoader(false);
      return;
    }
  
    if (accounttype === "") {
      toaster.show({
        message: 'Please enter a valid Account Type.',
        duration: 5000,
        type: 'error',
        position: 'middle',
      });
      setLoader(false);
      return;
    }
  
    if (accountNumber === "") {
      toaster.show({
        message: 'Please enter a valid Account number.',
        duration: 5000,
        type: 'error',
        position: 'middle',
      });
      setLoader(false);
      return;
    }
  
    if (docName === "") {
      setDocNameError('Please enter a valid document name');
      setLoader(false);
      return;
    }
  
    if (docDesc === "") {
      setDocDescError('Please enter a valid description');
      setLoader(false);
      return;
    }
  
    try {
      const data = new FormData();
      data.append('file', doc);
      data.append('description', docDesc);
      data.append('name', docName);
  
      await submitFile(data); // wait for the submission to complete
    } catch (error) {
      console.error('Error during submission:', error);
    } finally {
      setLoader(false); // Set loader to false after submission is complete
    }
  };
  
  const submitFile = async (data) => {
    try {
      const tempObj = {
        id: choices.length + 1,
        locale: 'en',
        accountType: accounttype,
        accountId: parseInt(accountNumber, 10),
        amount: amount,
      };
  
      const mobileMoneyPaymentTypeId = 4;
      const reportData = {
        paymentTypeId: mobileMoneyPaymentTypeId,
        transactionDate: moment(date).format(moment.HTML5_FMT.DATE),
        transactionId: null,
        paymentProof: null,
        split: addedAccounts ? [tempObj] : choices,
      };
  
      const clientId = await accountService.getClientId();
      const response = await accountService.docUpload(clientId, data);
      reportData.paymentProof = response.data.resourceId;
  
      await accountService.selfReporting(clientId, reportData);
  
      toaster.show({
        message: 'Your application has been submitted now pending approval.',
        duration: 5000,
        type: 'success',
        position: 'middle',
      });
      setAddedAccounts(false);
      (navigation as any).replace('Home', { screen: 'DashBoard' });
    } catch (error) {
      console.error('Error in submitFile:', error);
      toaster.show({
        message: 'An error occurred during submission. Please try again.',
        duration: 5000,
        type: 'error',
        position: 'middle',
      });
    }
  };

  const onChangeAccountType = (e) => {
    setAccountType(e);
    if (e === 'Savings') {
      setAccounts(accountsMap['Savings']);
    } else if (e === 'Loan') {
      setAccounts(accountsMap['Loan']);
    }
  };

  const onChangeAccountNumber = (e: any) => {
    setAccountNumber(e);
  };

  const accountType = [
    {
      label: 'Loan',
      value: 'Loan',
    },
    {
      label: 'Savings',
      value: 'Savings',
    },
  ];

  const [doc, setDoc] = useState();
  const [imagePreview, setImagePreview] = useState();

  const pickDocument = async () => {
    await DocumentPicker.getDocumentAsync({
      type: '*/*',
      copyToCacheDirectory: true,
    }).then((response: { type?: any; file?: any; uri?: any }) => {
      if (response.type === 'success') {
        const { file } = response;
        let { uri } = response;

        if (Platform.OS === 'android' && uri[0] === '/') {
          uri = `file://${uri}`;
          uri = uri.replace(/%/g, '%25');
        }
        setImagePreview(uri);
        setDoc(file);
      }
    });
  };

  console.log(loader)

  return (
    <View style={[DashboardStyles.web]}>
      <Row>
        <Col customStyles={{ flex: 1, alignItems: 'center' }}>
          <SideMenu navigation={navigation} page="Deposit" />
        </Col>

        <Col customStyles={{ flex: 4 }}>
          {/* <View> */}

          <Row>
            <Col
              customStyles={{
                flex: 1,
              }}
            >
              <Card style={[DashboardStyles.card, { paddingLeft: 20 }]}>
                <ImageBackground
                  source={require('../../assets/kb-blue-background.png')}
                  style={[
                    {
                      flex: 1,
                      width: 200,
                      marginTop: 10,
                      paddingVertical: 100,
                      height: '90%',
                      position: 'absolute',
                      right: 0,
                    },
                  ]}
                />

                <Card.Content>
                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      <Text style={styles.cardHeader}>Submit A Deposit</Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ width: '60%' }}>
                      <Row>
                        <Col customStyles={{ flex: 1 }}>
                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.label}>
                                Select Account Type
                                {<Text style={{color: 'red'}}>*</Text>}
                              </Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Row>
                                <Col customStyles={{ flex: 1 }}>
                                  <SafeAreaView>
                                    <DropDown
                                      mode="outlined"
                                      visible={showDropDown}
                                      showDropDown={() => setShowDropDown(true)}
                                      onDismiss={() => setShowDropDown(false)}
                                      value={accounttype}
                                      setValue={(e: any) => onChangeAccountType(e)}
                                      list={accountType}
                                    />
                                  </SafeAreaView>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <RequiredTextInput
                                label="Amount"
                                value={amount}
                                onChange={(e: any) => setAmount(e)}
                                required
                                error={amountError}
                                mode="outlined"
                                outlineColor="#2679b0"
                                activeOutlineColor="#2679b0"
                                style={[styles.amountView]}
                              />
                            </Col>
                          </Row>

                          {show === true ? (
                            <Row>
                              <Col customStyles={{ flex: 1 }}>
                                <Text style={styles.label}>Accounts selected</Text>

                                {choices.map((x, i) => (
                                  <Text style={styles.selac} key={i}>
                                    {(x as any).accountType} A/C.#0000000{(x as any).accountId} UGX.
                                    {(x as any).amount}
                                  </Text>
                                ))}
                              </Col>
                            </Row>
                          ) : null}

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.label}>Transaction Date</Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <View>
                                <Button
                                  onPress={() => setOpen(true)}
                                  uppercase={false}
                                  mode="outlined"
                                  style={{ padding: 10 }}
                                >
                                  {moment(date).format(moment.HTML5_FMT.DATE)}
                                </Button>
                              </View>
                              <DatePickerModal
                                locale="en"
                                mode="single"
                                visible={open}
                                onDismiss={onDismissSingle}
                                date={date}
                                onConfirm={onConfirmSingle}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <RequiredTextInput
                                label="Document Name"
                                value={docName}
                                onChange={(e: any) => setDocName(e)}
                                required
                                error={docNameError}
                                mode="outlined"
                                outlineColor="#2679b0"
                                activeOutlineColor="#2679b0"
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.label}>Upload Document </Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <View>
                                <Button onPress={pickDocument} mode="contained">
                                  Select Document
                                </Button>
                                {imagePreview ? (
                                  <View style={{ padding: 10 }}>
                                    <Image
                                      source={{ uri: imagePreview }}
                                      style={{ width: 200, height: 200 }}
                                    />
                                  </View>
                                ) : null}
                              </View>
                            </Col>
                          </Row>
                        </Col>

                        <Col
                          customStyles={{
                            flex: 1,
                            marginLeft: 20,
                          }}
                        >
                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.label}>
                                Account Number
                                {<Text style={{color: 'red'}}>*</Text>}
                              </Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Row>
                                <Col customStyles={{ flex: 1 }}>
                                  <SafeAreaView>
                                    <DropDown
                                      mode="outlined"
                                      visible={showDropDown1}
                                      showDropDown={() => setShowDropDown1(true)}
                                      onDismiss={() => setShowDropDown1(false)}
                                      value={accountNumber}
                                      setValue={onChangeAccountNumber}
                                      list={accountsMap[accounttype] || accounts}
                                    />
                                  </SafeAreaView>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.label}>Select Account</Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <View>
                                <Button
                                  onPress={showAccounts}
                                  mode="contained"
                                  style={{ padding: 12 }}
                                >
                                  Add Extra Account
                                </Button>
                              </View>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <Text style={styles.label}>Transaction ID</Text>
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <TextInput
                                mode="outlined"
                                outlineColor="#2679b0"
                                activeOutlineColor="#2679b0"
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col customStyles={{ flex: 1 }}>
                              <RequiredTextInput
                                  label="Document Description"
                                  value={docDesc}
                                  onChange={(e: any) => setDocDesc(e)}
                                  required
                                  error={docDescError}
                                  mode="outlined"
                                  outlineColor="#2679b0"
                                  activeOutlineColor="#2679b0"
                              />
                            </Col>
                          </Row>

                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ width: '75%' }}>
                      <View style={styles.divider} />
                    </Col>
                  </Row>

                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      {loader && <Activity />}

                      <TouchableHighlight onPress={() => onSubmit()} disabled={loader}>
                        <View style={[styles.depositButton, loader && styles.disabledButton]} >
                          <Text style={{ color: 'white', fontWeight: 'bold' }}>{loader ? 'Processing...' : 'Send'}</Text>
                        </View>
                      </TouchableHighlight>
                    </Col>
                  </Row>
                </Card.Content>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Footer />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cardHeader: {
    fontSize: 30,
    color: '#2679b0',
    fontWeight: 'bold',
    marginTop: 20,
  },
  divider: {
    borderBottomWidth: 2,
    borderBottomColor: '#2679b0',
    marginTop: 20,
    // marginLeft: 5,
    marginBottom: 20,
    // width: '96%',
  },
  depositButton: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'rgb(30,136,229)',
    padding: 10,
    borderRadius: 15,
    marginTop: 20,
    width: 200,
  },
  disabledButton: {
    backgroundColor: '#a9a9a9',
  },
  label: {
    fontSize: 18,
    color: '#2679b0',
    fontWeight: 'bold',
  },
  selac: {
    fontSize: 16,
    color: '#000',
    fontWeight: 'bold',
  },
  subtiteLabel: {
    fontSize: 14,
    color: 'grey',
    fontWeight: 'bold',
  },
  mobilemoneywebImages: {
    width: '90%',
    height: 80,
    // borderRadius: 20,
    borderBottomRightRadius: 20,
  },
});


