/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';

import { View, Text, StyleSheet, ImageBackground, TouchableOpacity, Alert } from 'react-native';
import { tawk } from './tawk';
import { useSelector } from 'react-redux';
import { AccountService } from '../common/account.service';

const Footer = () => {
  const displayName = useSelector((state) => (state as any).loggedInUser?.displayName);
  const fsp = useSelector((state) => (state as any).loggedInTenant);

  useEffect(() => {
    if (displayName) {
      tawk(displayName, fsp);
    }
  }, [displayName, fsp]);
  return (
    <View style={styles.container}>
      <ImageBackground
        source={require('../../assets/kb-white.png')}
        style={{
          width: 60,
          marginTop: 20,
          marginLeft: -15,
          height: 120,
          position: 'absolute',
          left: 1,
        }}
      />

      <Text style={styles.footerText}>
        Help | Support | Log Out &copy; kanzubanking {new Date().getFullYear()}
      </Text>
    </View>
  );
};

export default Footer;

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2679b0',
    height: '20%',
    marginTop: 20,
  },
  footerText: {
    color: 'white',
  },
});
