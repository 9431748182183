import { StyleSheet, Platform } from 'react-native';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';

export const styles = StyleSheet.create({
  active: {
    borderBottomWidth: 2,
    borderBottomColor: 'rgb(41, 113, 182)',
    marginTop: 5,
    width: '100%',
    color:'rgb(41, 113, 182)'
  },
  verticalDivider: {
    borderLeftWidth: 2,
    borderLeftColor: 'lightgrey',
    marginRight: '3px',
    height: '100px',
  },
  feel: {
    borderWidth: 2,
    borderColor: 'yellow',
  },
  mobile: {
    display: Platform.OS === 'web' ? 'none' : 'flex',
  },
  web: {
    display: Platform.OS === 'web' ? 'flex' : 'none',
    backgroundColor: '#f7f7f7',
    fontSize: 18,
  },
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2679b0',
    height: Platform.OS === 'ios' ? hp('50%') : hp('60%'),
    padding: wp('10%'),
  },
  recentTransactionContainer: {
    flexDirection: 'column',
    padding: 30,
  },
  totalsaving: {
    color: '#03d85e',
    fontSize: 20,
    marginTop: 20,
  },
  actionIcons: {
    width: 59,
    height: 55,
  },
  depositIcons: {
    width: 50,
    height: 50,
  },
  dashboardIcons: {
    width: 60,
    height: 25,
    position: 'absolute',
  },
  savingDashboardIcons: {
    width: 60,
    height: 25,
    position: 'absolute',
  },
  symbolImage: {
    width: 40,
    height: 40,
  },
  mifosImage: {
    width: 40,
    height: 40,
    marginTop: 5,
  },
  sideBarIcons: {
    width: 25,
    height: 25,
  },
  img: {
    width: 100,
    height: 100,
    borderWidth: 1,
    borderRadius: 150 / 2,
    borderColor: '#000',
    // backgroundColor: 'gray',
    // resizeMode: 'contain',
  },
  loanActionIcons: {
    width: 100,
    height: 50,
  },
  transactionIcons: {
    marginTop: 2,
    width: 25,
    height: 25,
  },
  currency: {
    color: 'white',
    fontSize: 40,
    marginTop: 22,
  },
  ugx: {
    color: 'white',
    fontSize: 18,
  },
  divider: {
    borderBottomWidth: 2,
    borderBottomColor: 'white',
    marginTop: hp('6'),
    width: wp('81%'),
  },
  recentTransactionDivider: {
    borderBottomWidth: 2,
    borderBottomColor: 'lightgrey',
    marginTop: 5,
    width: '100%',
  },
  transactionsDivider: {
    borderBottomWidth: 2,
    borderBottomColor: 'lightgrey',
    marginTop: 20,
    width: wp('83%'),
  },
  quickActionView: {
    color: 'white',
    marginTop: hp('2'),
    alignSelf: 'flex-start',
  },
  quickActionsText: {
    color: 'white',
    fontSize: 20,
  },
  iconsText: {
    color: 'white',
    fontSize: Platform.OS === 'ios' ? wp('0') : wp('3'),
  },
  recentTransactionText: {
    fontSize: 18,
    color: '#737373',
    fontWeight: 'bold',
  },
  SeeAllText: {
    fontSize: 18,
    color: '#2175ad',
    // fontWeight:"bold"
  },
  recentTransactionColor: {
    color: '#737373',
  },
  saccoFigures: {
    fontWeight: 'bold',
    color: '#606060',
    fontSize: 15,
  },
  dateLabel: {
    color: '#2679b0',
    fontWeight: 'bold',
    fontSize: 18,
  },
  saccoCard: {
    flexDirection: 'row',
    marginTop: 15,
    width: '100%',
    height: '150px',
  },
  card: {
    overflow: 'hidden',
    shadowColor: '#000000',
    shadowRadius: 10,
    shadowOpacity: 0.2,
    width: '95%',
  },
  cardStyles: {
    width: '97.5%',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  depositCard: {
    height: '100%',
    backgroundColor: '#62c341',
  },
  sideBarCard: {
    overflow: 'hidden',
    shadowColor: '#000000',
    shadowRadius: 10,
    shadowOpacity: 0.2,
    width: '87%',
    height: '672px',
    marginTop: -10,
    paddingTop: 30,
  },
  sideBarText: {
    color: 'grey',
    fontSize: 14,
  },
  savingsText: {
    fontWeight: 'bold',
    color: 'grey',
    fontSize: 18,
  },
  saccoText: {
    fontWeight: 'bold',
    color: 'grey',
    fontSize: 15,
  },
  currencyText: {
    fontWeight: 'bold',
    color: '#606060',
    fontSize: 23,
  },
  cardSubtitleText: {
    marginTop: 20,
    color: 'grey',
    fontSize: 12,
  },

  datelabel: {
    color: '#2679b0',
    fontWeight: 'bold',
  },
  versionlabel: {
    fontWeight: 'bold',
    color: '#dc3832',
    fontSize: 16,
  },
  versionContext: {
    marginLeft: '2px',
    fontSize: 13,
    color: 'grey',
    // fontWeight: 'bold',
  },
  versionSubtitle: {
    color: '#a0a0a0',
    marginTop: 5,
    fontSize: 10,
    fontWeight: 'bold',
  },
  cardHeaders: {
    fontWeight: 'bold',
    fontSize: 18,
    color: 'grey',
  },
});

export default { styles };

