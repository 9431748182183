/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import { Image, View, Text, StyleSheet, ImageBackground, ActivityIndicator } from 'react-native';
import { Card, Button, Title, TextInput } from 'react-native-paper';

import { styles as DashboardStyles } from '../dashboard/DashboardStyles';
import SideMenu from '../../components/SideMenu/SideMenu';
import Footer from '../../components/footer/footer';
import Table from '../../components/dataTable/Table';
import { useSelector, Provider } from 'react-redux';
import { store } from '../../redux/index';

import Row, { Col } from '../../components/Row';
import TrackScreenHit from "../../utils/analytics"

function PageBody({ navigation, route }: any) {
  const LoanAccounts = useSelector((state) => (state as any).loanAccounts);
  const SavingsAccounts = useSelector((state) => (state as any).savingsAccounts);
  const ShareAccounts = useSelector((state) => (state as any).shareAccounts);
  const profileImage = useSelector((state) => (state as any).image);
  const [mode, setMode] = useState('Loans');
  const [showClosed, setShowClosed] = useState(false);

  const sortedLoanAccounts = LoanAccounts.filter((acc : any) => acc.status.active === true).slice().sort((a: { accountNo: number; }, b: { accountNo: number; }) => b.accountNo - a.accountNo);
  const sortedSavingsAccounts = SavingsAccounts.filter((acc : any) => acc.status.active === true).slice().sort((a: { accountNo: number; }, b: { accountNo: number; }) => b.accountNo - a.accountNo);
  const sortedSharesAccounts = ShareAccounts.slice().sort((a: { accountNo: number; }, b: { accountNo: number; }) => b.accountNo - a.accountNo);

  const sortedClosedLoanAccounts = LoanAccounts.filter((acc : any) => acc.status.closed === true).slice().sort((a: { accountNo: number; }, b: { accountNo: number; }) => b.accountNo - a.accountNo);
  const sortedClosedSavingsAccounts = SavingsAccounts.filter((acc : any) => acc.status.closed === true).slice().sort((a: { accountNo: number; }, b: { accountNo: number; }) => b.accountNo - a.accountNo);
  const sortedClosedSharesAccounts = ShareAccounts.filter((acc : any) => acc.status.closed === true).slice().sort((a: { accountNo: number; }, b: { accountNo: number; }) => b.accountNo - a.accountNo);

  TrackScreenHit("Account Page")

  useEffect(() => {
    if (route) {
      if (route.params?.tab === 'loans') {
        setMode('Loans');
      } else if (route.params?.tab === 'savings') {
        setMode('Savings');
      }
    }
  }, [route]);
  

  const loanTableTitles = [
    'Status',
    'Account No',
    'Loan Account',
    'Original Loan',
    'Loan Balance',
    'Amount Paid',
    'Type',
  ];
  const savingsTableTitles = ['Status', 'Account No', 'Account', 'Account Type', 'Balance'];

  const sharesTableTitles = ['Status', 'Account #', 'Account', 'Date', 'Total Shares', 'Purchased Price', 'Amount Received' ];

  return (
    <View style={[DashboardStyles.web]}>
      <Row>
        <Col customStyles={{ flex: 1.2, alignItems: 'center' }}>
          <SideMenu navigation={navigation} page="Accounts" style={{ height: '30px' }} />
        </Col>

        <Col customStyles={{ flex: 4, paddingTop: '10px' }}>
          <ImageBackground
            source={require('../../assets/kb-blue-background.png')}
            style={[
              {
                flex: 1,
                width: 200,
                marginTop: 10,
                paddingVertical: 100,
                height: '90%',
                position: 'absolute',
                right: 0,
              },
            ]}
          />

          <View style={{ flexDirection: 'column', flex: 2.3 }}>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: '100%' }}>
                <Col
                  customStyles={{
                    flex: 1,
                    marginBottom: '-14px',
                  }}
                >
                  <Row>
                    <Col
                      customStyles={{
                        flex: 1,
                      }}
                    >
                      <Button
                        mode="text"
                        onPress={() => {setMode('Loans'), setShowClosed(false)}}
                        style={mode === 'Loans' ? styles.active : null}
                        color={mode === 'Loans' ? '#fff' : 'rgb(41, 113, 182)'}
                      >
                        Loans
                      </Button>
                    </Col>

                    <Col
                      customStyles={{
                        flex: 1,
                      }}
                    >
                      <Button
                        onPress={() => {setMode('Savings'), setShowClosed(false)}}
                        style={mode === 'Savings' ? styles.active : null}
                        color={mode === 'Savings' ? '#fff' : 'rgb(41, 113, 182)'}
                      >
                        Savings
                      </Button>
                    </Col>
                    <Col
                      customStyles={{
                        flex: 1,
                      }}
                    >
                      <Button
                        onPress={ () => {setMode('Share'), setShowClosed(false)}}
                        style={mode === 'Share' ? styles.active : null}
                        color={mode === 'Share' ? '#fff' : 'rgb(41, 113, 182)'}
                      >
                        Share
                      </Button>
                    </Col>
                    <Col
                      customStyles={{
                        flex: 5,
                      }}
                    >
                      
                    </Col>
                  </Row>
                </Col>
                <View style={[styles.horizontalDivider]} />
              </View>
            </View>

            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '100%', marginTop: '10px' }}>
                <Row>
                  <Col customStyles={{ flex: 0.3 }}>
                    {mode === 'Loans' ? (
                      <Image
                        style={[styles.dashboardIcons]}
                        source={require('../../assets/apply_loan.png')}
                      />
                    ) : mode === 'Savings' ? (
                      <Image
                        style={[
                          styles.dashboardIcons,
                          { height: '70px', width: 70, position: 'absolute' },
                        ]}
                        source={require('../../assets/total_savings.png')}
                      />
                    ) : mode === 'Share' ? (
                      <Image
                        style={[
                          styles.dashboardIcons,
                          { height: '70px', width: 70, position: 'absolute' },
                        ]}
                        source={require('../../assets/total_savings.png')}
                      />
                    ) : null}
                  </Col>

                  <Col customStyles={{ flex: 1 }}>
                    <View>
                      <Title style={[styles.savingsText, { paddingTop: '14px' }]}>
                        {' '}
                        {mode} Account List
                      </Title>
                    </View>
                  </Col>

                  {
                    (sortedClosedLoanAccounts.length > 0 || sortedClosedSavingsAccounts.length > 0 || sortedClosedSharesAccounts.length > 0) ? 
                    <Col
                      customStyles={{
                        flex: 0.4,
                        marginTop: 10
                      }}
                    >
                      <Button
                        onPress={() => setShowClosed(!showClosed)}
                        style={{backgroundColor: 'rgb(41, 113, 182)', width: "fit-content"}}
                        color='#fff'
                      >
                        {showClosed ? `View Active ${mode}` : `View Closed ${mode}` }
                      </Button>
                    </Col>
                    : 
                    ''
                  }
                </Row>
              </View>
            </View>

            <View
              style={{
                flexDirection: 'row',
                marginTop: '15px',
                width: '100%',
                height: '520px',
              }}
            >
              <Card style={[styles.card, { paddingLeft: 20 }]}>
                <Card.Content>
                  <Row>
                    <Col customStyles={{ flex: 1 }}>
                      {mode === 'Loans' ? (
                        <Table
                          titles={loanTableTitles}
                          data={showClosed ? sortedClosedLoanAccounts : sortedLoanAccounts}
                          mode={mode}
                          navigation={navigation}
                        />
                      ) : mode === 'Share' ? (
                        <Table
                          titles={sharesTableTitles}
                          data={showClosed ? sortedClosedSharesAccounts : sortedSharesAccounts}
                          mode={mode}
                          navigation={navigation}
                        />
                      ) : (
                        <Table
                          titles={savingsTableTitles}
                          data={showClosed ? sortedClosedSavingsAccounts : sortedSavingsAccounts}
                          mode={mode}
                          navigation={navigation}
                        />
                      )}
                    </Col>
                  </Row>
                </Card.Content>
              </Card>
            </View>
          </View>
        </Col>
      </Row>

      <Footer />
    </View>
  );
}

export default function AccountsWeb({ navigation, route }: any) {
  return (
    <Provider store={store}>
      <PageBody navigation={navigation} route={route} />
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  active: {
    backgroundColor: 'rgb(41, 113, 182)',
  },
  actionButton: {
    fontWeight: 'bold',
  },
  inputs: {
    backgroundColor: 'transparent',
  },
  currencyText: {
    fontWeight: 'bold',
    color: '#606060',
    fontSize: 23,
  },
  details: {
    width: '95%',
    paddingBottom: '20px',
    paddingTop: '20px',
    fontSize: 14,
  },
  horizontalDivider: {
    borderBottomWidth: 2,
    borderBottomColor: 'lightgrey',
    marginTop: 5,
    width: '100%',
  },
  verticalDivider: {
    borderLeftWidth: 2,
    borderLeftColor: 'lightgrey',
    marginRight: '5px',
    height: '120px',
  },
  versionSubtitle: {
    color: '#a0a0a0',
    marginTop: 5,
    fontSize: 10,
    fontWeight: 'bold',
  },
  modeText: {
    color: '#a0a0a0',
    marginTop: 5,
    fontSize: 11,
    fontWeight: 'bold',
  },
  cardHeader: {
    fontSize: 30,
    color: '#2679b0',
    fontWeight: 'bold',
    marginTop: 20,
  },
  savingsText: {
    fontWeight: 'bold',
    color: '#000',
    fontSize: 20,
  },
  dashboardIcons: {
    width: 100,
    height: 40,
    marginLeft: '15%',
  },
  divider: {
    borderBottomWidth: 2,
    borderBottomColor: '#2679b0',
    marginTop: 20,
    // marginLeft: 5,
    marginBottom: 20,
    // width: '96%',
  },
  depositButton: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#62c341',
    padding: 10,
    borderRadius: 15,
    marginTop: 20,
    width: 200,
  },
  label: {
    fontSize: 18,
    color: '#2679b0',
    fontWeight: 'bold',
  },
  subtiteLabel: {
    fontSize: 14,
    color: 'grey',
    fontWeight: 'bold',
  },
  mobilemoneywebImages: {
    width: '90%',
    height: 80,
    // borderRadius: 20,
    borderBottomRightRadius: 20,
  },
  card: {
    overflow: 'hidden',
    shadowColor: '#000000',
    shadowRadius: 10,
    shadowOpacity: 0.1,
    width: '98%',
  },
});
