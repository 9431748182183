/* eslint-disable no-new */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React from 'react';

import { Text, View, Image, TouchableWithoutFeedback, ScrollView } from 'react-native';
import { Divider, Button } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { numberWithCommas } from '../../components/common/common';

import { styles } from './DashboardStyles';
import { Constants } from '../../Constants/Constants';
import { Col } from '../../components/Row';

const { LOADING_TEXT } = Constants;

export default function DashboardMobile({ navigation }: any) {
  const totalSavings = useSelector((state) => (state as any).totalSavings);
  const transactions = useSelector((state) => (state as any).transactions);

  const recentTxt = () => {
    const dates: any = [];
    transactions.map((x: any) => {
      if (dates.includes(x.transactionDate) === false) dates.push(x.transactionDate);
    });

    // @ts-expect-error TS(7006): Parameter 'x' implicitly has an 'any' type.
    const renderView = dates.map((x, i) => {
      return (
        <View key={i}>
          <View style={[{ marginTop: 20 }]}>
            <Text style={styles.dateLabel}>{x}</Text>
          </View>
          {transactions.map((j: any, k: any) => {
            if (j.transactionDate === x) {
              return (
                <View key={k}>
                  <View style={[{ flexDirection: 'row', paddingTop: 10 }]}>
                    <View style={[{ flex: 1, alignItems: 'center' }]}>
                      {j.plusMinus ? (
                        <MaterialCommunityIcons name="plus-circle" color="green" size={30} />
                      ) : (
                        <MaterialCommunityIcons name="minus-circle" color="red" size={30} />
                      )}
                    </View>

                    <View style={[{ flex: 2 }]}>
                      <Text style={styles.recentTransactionColor}>{j.transactionType}</Text>

                      <Text style={styles.recentTransactionColor}>{j.accountType}</Text>
                    </View>

                    <View style={[{ flex: 3, alignItems: 'flex-end' }]}>
                      <Text style={styles.recentTransactionColor}>
                        -UGX {numberWithCommas(j.amount) || 0}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.transactionsDivider} />
                </View>
              );
            }
          })}
        </View>
      );
    });
    return renderView;
  };
  return (
    <ScrollView>
      <View style={[styles.container]}>
        <Text style={styles.totalsaving}>Total Savings</Text>

        <Text style={styles.currency}>
          {totalSavings.length > 10 ? (
            <>
              <Text style={[styles.ugx, { fontSize: 13 }]}>UGX</Text>
              {totalSavings === LOADING_TEXT ? totalSavings : numberWithCommas(totalSavings)}
            </>
          ) : (
            <>
              <Text style={styles.ugx}>UGX </Text>
              {totalSavings === LOADING_TEXT ? totalSavings : numberWithCommas(totalSavings)}
            </>
          )}
        </Text>

        <Button
          style={{ shadowColor: '#009900', shadowRadius: 10, shadowOpacity: 1, marginLeft: 15, marginTop: 20 }}
          mode="contained"
          color="#009900"
          onPress={() =>
            navigation.navigate('Payment')
          }
        >
          Make A Deposit
        </Button>

        <View style={{...styles.divider, margin: 15}} />

        <View style={styles.quickActionView}>
          <Text style={styles.quickActionsText}>Quick Actions</Text>
        </View>

        <Divider />

        <View style={{ flexDirection: 'row', width:'100%', justifyContent: 'space-between', marginTop: 30 }}>
          <View style={[{ flex: 1, flexDirection:'column', alignItems:'center', justifyContent:'center' }]}>
            <TouchableWithoutFeedback onPress={() => navigation.navigate('Accounts')}>
              <Image style={styles.actionIcons} source={require('../../assets/account.png')} />
            </TouchableWithoutFeedback>
            <Text style={[styles.iconsText, {fontSize: 14, marginTop:10}]}>Accounts</Text>
          </View>

          <View style={{ flex: 1, flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
            <TouchableWithoutFeedback onPress={() => navigation.navigate('Deposit')}>
              <Image style={styles.actionIcons} source={require('../../assets/depo.png')} />
            </TouchableWithoutFeedback>
            <Text style={[styles.iconsText, {fontSize: 14, marginTop:10, textAlign:'center'}]}>Submit Deposit Slip</Text>
          </View>

          <View style={{ flex: 1, flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
            <TouchableWithoutFeedback onPress={() => navigation.navigate('Loan')}>
              <Image
                style={[styles.actionIcons, styles.loanActionIcons]}
                source={require('../../assets/loan.png')}
              />
            </TouchableWithoutFeedback>
            <Text style={[styles.iconsText, {fontSize: 14, marginTop:10} ]}>Apply for Loan</Text>
          </View>
        </View>

      </View>

      <View style={[styles.recentTransactionContainer]}>
        <View style={[{ flexDirection: 'row', justifyContent: 'space-between' }]}>
          <View>
            <Text style={styles.recentTransactionText}>Recent Transactions</Text>
          </View>
        </View>
        {transactions.length > 0 ? (
          recentTxt()
        ) : (
          <Text style={{ fontWeight: 'bold', paddingTop: 10 }}>No Recent Transactions </Text>
        )}
      </View>
    </ScrollView>
  );
}
