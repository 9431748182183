import React from 'react';

export const tawk = (displayName, fsp) => {
  window.Tawk_API = window.Tawk_API || {};
  window.Tawk_API.visitor = {
    name: displayName + ' - ' + fsp,
  };

  setTimeout(() => {
    (function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/5947f79950fd5105d0c81cf1/1gk903vb4';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, 100);

  window.Tawk_API.onLoad = function () {
    window.Tawk_API.showWidget();
  };
};
